<template>
  <div id="app">
    <header class="header-outside">
      <h1>EV-Dashboard</h1>
    </header>
    <EVDashboard />
  </div>
</template>

<script>
import EVDashboard from "./components/EVDashboard";

export default {
  name: "App",
  components: {
    EVDashboard,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}

.header-outside {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  border-bottom: 3px solid rgb(93, 212, 157);
  color: white;
}
</style>