<template>
  <div class="container">
    <div v-for="data in full_data" :key="data.nr">
      <div class="box">
        <h1 class="box-header">
          Laadpaal - {{ data.nr }}
          <span
            v-if="data.comStatus == 'UP' && data.serviceStatus == 'IN_SERVICE'"
            style="color: #06d6a0"
            >●</span
          >
          <span v-else style="color: #ef476f">●</span>
        </h1>
        <table v-if="data.ocppStatus == 'CHARGING'" class="charging">
          <tr>
            <th>Kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>{{ data.ocppStatus }}</th>
          </tr>
        </table>

        <table v-if="data.ocppStatus == 'AVAILABLE'" class="available">
          <tr>
            <th>Kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>{{ data.ocppStatus }}</th>
          </tr>
        </table>

        <table v-if="data.ocppStatus == 'SUSPENDED_EV'" class="suspended">
          <tr>
            <th>kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>SUSPENDED</th>
          </tr>
        </table>

        <table v-if="data.ocppStatus == 'SUSPENDED_EVSE'" class="charging">
          <tr>
            <th>kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>ON HOLD</th>
          </tr>
        </table>

        <table v-if="data.ocppStatus == 'PREPARING'" class="suspended">
          <tr>
            <th>kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>PREPARING</th>
          </tr>
        </table>

        <table v-if="data.ocppStatus == 'FINISHING'" class="finishing">
          <tr>
            <th>kwh</th>
            <th>Charging Time</th>
            <th>Occupation</th>
          </tr>
          <tr>
            <th>{{ data.kwh }}</th>
            <th>{{ data.chargingTime }}</th>
            <th>FINISHING</th>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "EVDashboard",
  data() {
    return {
      full_data: [],
    };
  },
  mounted() {
    axios({
      method: "GET",
      url: "https://car-charged-notifications.herokuapp.com/rest/services/chargingstations/xpg_kontich_pbl24e/all",
    }).then((res) => {
      this.full_data = res.data;
      console.log(res.data);
    });
  },
};
</script>
<style>
* {
  margin: 0px;
}

body {
  background-color: #465166;
}

.container {
  margin: 20px;
  /* background-color: greenyellow; */
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  color: black;
}

.box {
  width: 377px;
  height: auto;
  background-color: blue;
  margin: 20px;
  flex: 1;
  text-align: center;
  box-shadow: 1px 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.box-header {
  background-color: #2a3536;
  padding: 2px;
  color: white;
}

.charging {
  background-color: #f7f700;
}

.available {
  background-color: #018e42;
}

.suspended {
  background-color: rgb(255, 140, 0);
}

.finishing {
  background-color: rgba(196, 44, 33, 0.961);
}

table {
  background-color: rgba(58, 76, 78, 0.872);
  border-collapse: collapse;
  width: inherit;
  font-size: 20px;
}

th {
  padding: 5px;
}
</style>
